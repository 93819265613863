<template>
  <el-pagination
    class="pagination"
    :current-page="page"
    :page-size="pagesize"
    :pager-count="pagerCount"
    layout="prev, pager, next"
    @current-change="handleCurrentChange"
    :total="total"
  />
</template>
<script>
export default {
  props: {
    page: {
      type: Number,
      deafult: 1,
    },
    pagesize: {
      type: Number,
      deafult: 10,
    },
    pagerCount: {
      type: Number,
      deafult: 11,
    },
    totalPage: Number,
    total: Number,
  },
  methods: {
    handleCurrentChange(e) {
      this.$emit("change", e);
    },
  },
};
</script>
<style lang="less" scoped>
.pagination {
  margin: 24px 0 32px;
  display: flex;
  ::v-deep {
    button {
      margin-right: 5px;
      background-color: rgba(255, 255, 255, 0);
      color: #666666;
      font-size: 14px;
    }
    button.is-disabled,
    button:disabled,
    .btn-prev {
      background-color: rgba(255, 255, 255, 0);
      color: #666666;
      font-size: 14px;
    }
  }
}

::v-deep .el-pager {
  li {
    margin-right: 5px;
    background: rgba(255, 255, 255, 0);
    font-size: 14px;
    color: #666666;
  }

  .is-active {
    background: #c9fa5b !important;
    border-radius: 4px !important;
    color: #000000 !important;
  }
}
</style>
